/**
 * Created by osirvent on 08/04/2016.
 */
angular
    .module('annexaApp')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider

            .state('annexa.querys', {
                url: '/querys',
                redirectTo: 'annexa.querys.executeQuery',
                views: {
                    "": {
                        controller: 'QuerysController',
                        templateUrl: './views/layout/querys.html'
                    }
                },
                data: {
                    title: 'global.querys.toptitle',
                    displayName: 'global.querys.toptitle',
                    authenticate: true,
                    permissions: {
                        only: ['si_query_execute','si_report_execute']
                    }
                }
            })
            .state('annexa.querys.executeQuery', {
                url: '/execute',
                views: {
                    "mainquerys@annexa.querys": {
                        controller: 'ExecuteQueryController',
                        templateUrl: './views/layout/executeQuery.html'
                    }
                },
                data: {
                    title: 'global.querys.toptitle',
                    displayName: 'global.querys.execute.toptitle',
                    authenticate: true,
                    permissions: {
                        only: 'si_query_execute'
                    }
                }
            })
            .state('annexa.querys.executeReport', {
                url: '/report',
                views: {
                    "mainquerys@annexa.querys": {
                        controller: 'ExecuteReportController',
                        templateUrl: './views/layout/executeReport.html'
                    }
                },
                data: {
                    title: 'global.querys.toptitle',
                    displayName: 'global.querys.report.toptitle',
                    authenticate: true,
                    permissions: {
                        only: 'si_report_execute'
                    }
                }
            });
    }]);