angular
    .module('annexaApp')
    .factory('QueryFactory',['$q', '$http','Language', 'HelperService', '$filter', '$rootScope', 'DialogsFactory', function($q, $http, Language, HelperService, $filter, $rootScope, DialogsFactory) {
        var factory = {};

        factory.createQueryParameterField = function(queryQueryParameter, required, noEditable, colClass, labelClass) {
            var form = new AnnexaFormly();
            var data = {
                row: false,
                colClass: 'col-sm-12',
                labelClass:  ((labelClass)?labelClass : 'label-strong')
            };
            var data2 = {
                informed: true,
                row: true,
                colClass: 'col-sm-12',
                labelClass:  ((labelClass)?labelClass : 'label-strong')
            };

            var type = 'text';
            var step = undefined;
            if(queryQueryParameter && queryQueryParameter.queryParameter) {
                switch (queryQueryParameter.queryParameter.queryParameterType) {
                    case 'INTEGER':
                        type = 'number';
                        step = '1';
                        break;
                    case 'DOUBLE':
                        type = 'number';
                        step = '0.01';
                        break;
                }

                if (queryQueryParameter.queryParameter.queryParameterType == 'DATE') {
                    var datepickerOptions = {
                        format: 'dd/MM/yyyy',
                        initDate: new Date(),
                        showWeeks: false,
                        startingDay: 1
                    }

                    return form.createField(
                        queryQueryParameter.queryParameter.alias,
                        'annexaDatepickerRow',
                        ((colClass)?colClass : 'col-sm-12'),
                        new AnnexaFormlyFieldDatepickerTemplateOptions(
                            'text',
                            queryQueryParameter.queryParameter[Language.getActiveColumn()],
                            required,
                            datepickerOptions
                        ),
                        data,
                        ((!noEditable) ? "false" : "true"));

                }
                if (queryQueryParameter.queryParameter.queryParameterType == 'BOOLEAN') {
                    return form.createField(
                        queryQueryParameter.queryParameter.alias+"_BOOLEAN",
                        'annexaSelectRow',
                        ((colClass)?colClass : 'col-sm-12'),
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            queryQueryParameter.queryParameter.alias,
                            'id',
                            'description',
                            [
                                {id:'1', description:'global.literals.yes'},
                                {id:'0', description:'global.literals.no'}
                            ],
                            true
                        ),
                        data2,
                        ((!noEditable) ? "false" : "true"));
                }else if (queryQueryParameter.queryParameter.queryParameterType == 'BOOLEAN_NULL') {
                    return form.createField(
                        queryQueryParameter.queryParameter.alias+"_BOOLEAN_NULL",
                        'annexaSelectRow',
                        ((colClass)?colClass : 'col-sm-12'),
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            queryQueryParameter.queryParameter.alias,
                            'id',
                            'description',
                            [
                                {id:'-1', description:'global.literals.empty'},
                                {id:'1', description:'global.literals.yes'},
                                {id:'0', description:'global.literals.no'}
                            ],
                            false
                        ),
                        data2,
                        ((!noEditable) ? "false" : "true"));

                } else {
                    return form.createField(
                        queryQueryParameter.queryParameter.alias,
                        'annexaInputRow',
                        ((colClass)?colClass : 'col-sm-12'),
                        new AnnexaFormlyFieldTemplateOptions(
                            type,
                            queryQueryParameter.queryParameter[Language.getActiveColumn()],
                            required,
                            false,
                            step,
                            900
                        ),
                        data,
                        ((!noEditable) ? "false" : "true"));
                }
            }
        };

        factory.executeQuery = function(queryId, additionalFilter, page, size, columnsOnly){
            var deferred = $q.defer();
            if(queryId && additionalFilter){
                var additionalFilterAux = angular.copy(additionalFilter);
                if(columnsOnly && columnsOnly == true){
                    additionalFilterAux.columnsOnly = columnsOnly;
                }
                if(additionalFilterAux){
                    var props = Object.getOwnPropertyNames(additionalFilterAux);
                    if(props){
                        _.forEach(props, function(prop){
                            if(additionalFilterAux[prop] == null){
                                additionalFilterAux[prop] = undefined;
                            } else if (additionalFilterAux[prop] instanceof Date) {
                            	additionalFilterAux[prop] = new Date(Date.UTC(additionalFilterAux[prop].getFullYear(),additionalFilterAux[prop].getMonth(),additionalFilterAux[prop].getDate(),00,00,00));
                            }
                        })
                    }
                }
                $http({
                    url: './api/query/executeQuery/'+queryId,
                    method: 'GET',
                    params:{
                        aditional_filter: additionalFilterAux,
                        page: ((page)?page:0),
                        size: ((size)?size:10)
                    }
                }).then(function(data) {
                    if(data && data.status && (data.status == 500 || data.status == 406 || data.status == 403 || data.status == 404)){
                        deferred.reject(data);
                    }else{
                        deferred.resolve(JSOG.decode(data.data));
                    }
                }).catch(function (error) {
                    deferred.reject(error);
                });


            }else{
                deferred.reject('No query or query parameters');
            }
            return deferred.promise;
        }

        factory.exportQuery = function(queryId, additionalFilter, modal){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
	            var additionalFilterAux = angular.copy(additionalFilter);
	            if(additionalFilterAux){
	                var props = Object.getOwnPropertyNames(additionalFilterAux);
	                if(props){
	                    _.forEach(props, function(prop){
	                        if(additionalFilterAux[prop] == null){
	                            additionalFilterAux[prop] = undefined;
	                        }
	                    })
	                }
	            }
	            var req = {
	                url: './api/query/exportQuery/'+queryId,
	                responseType: 'arraybuffer',
	                method: "GET",
	                params: {
	                    aditional_filter: JSOG.encode(additionalFilterAux)
	                }
	            };
	            $http(req).then(function(resp){
	                var serverData = new Blob([resp.data], {type: resp.headers()['content-type']});
	                $.fn.dataTable.fileSave(serverData, 'exportQuery.csv');
	            });
           }).catch(function (data) {
	               //Empty
	       });
        }

        factory.generateReportQuery = function(reportId, additionalFilter){
            var req = {
                url: './api/report/executeReport/'+reportId,
                responseType: 'arraybuffer',
                method: "GET",
                params: {
                    aditional_filter: JSOG.encode(additionalFilter)
                }
            };
            $http(req).then(function(resp){
                var serverData = new Blob([resp.data], {type: resp.headers()['content-type']});
                $.fn.dataTable.fileSave(serverData, 'executeReport.docx');
            }).catch(function (error) {
            	var errorAux = ((error.data)?error.data:undefined);
				if(errorAux){
					errorAux = String.fromCharCode.apply(null, new Uint8Array(errorAux));
					if(errorAux){
						errorAux = angular.fromJson(errorAux);
					}else{
						errorAux = undefined;
					}
				}else{
					errorAux = undefined;
				}

                $rootScope.$broadcast('executeReportResultError', {reportId:reportId, error: errorAux});
            });;
        }

        return factory;
    }]);