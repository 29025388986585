/**
 * Created by osirvent on 08/04/2016.
 * Modified by aguerrero on 14/04/2016.
 */
angular
    .module('annexaApp')
    .controller('QuerysController',['$rootScope', '$scope', '$state', 'HeaderService', 'Language', 'ErrorFactory', 'RouteStateFactory', 'TableFilterFactory',
        function ($rootScope, $scope, $state, HeaderService, Language, ErrorFactory, RouteStateFactory, TableFilterFactory) {
        $scope.languageColumn = Language.getActiveColumn();
        var vm = this;

    }])
    .controller('ExecuteQueryController',['$rootScope', '$scope', '$state', '$filter', '$stateParams', 'HeaderService', '$timeout', 'RouteStateFactory', 'TableFilterFactory', 'RestService', 'QuerysModals', 'AnnexaFormlyFactory','QueryFactory','AnnexaPermissionsFactory', 'HelperService',
        function ($rootScope, $scope, $state, $filter, $stateParams, HeaderService, $timeout, RouteStateFactory, TableFilterFactory, RestService, QuerysModals, AnnexaFormlyFactory,QueryFactory,AnnexaPermissionsFactory,HelperService) {
        if (!$scope.tfilter){
            var i = 0;
            $scope.tfilter = [
                {id: $scope.languageColumn, type: 'text', order: i++, label: 'global.literals.name', preFilter:HelperService.getPrefilter('querys','tableListOfQuery','language'), negated:HelperService.getPrefilterNegated('querys','tableListOfQuery','language')}
            ];
        }
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.tfilter = routeState.state;
        }
        $scope.getFilterCall = getFilterCall;
        $scope.getFilterCallAux = getFilterCallAux;
        $scope.submit = submit;

        function submit(val) {
            $scope.$broadcast('filterData', {});
        };

        HeaderService.onChangeState($scope, onChangeState);
        HeaderService.changeState($state.current);
        var userActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.querys.execute.executeQuery','openExecuteQuery([data])','fa-cog', false),[]);


        $scope.openExecuteQuery = function (id) {
            if(id) {
                RestService.findOne('Query', id)
                    .then(function(data) {
                        if(data && data.data) {
                            var query = JSOG.decode(data.data);
                            var modal = angular.copy(QuerysModals.queryExecute);
                            modal.annexaFormly.model = {};
                            modal.annexaFormly.model.row1 =  {name: query[$scope.languageColumn], description:query.description, query:query.query};
                            modal.annexaFormly.model.additionalFilter =  {};
                            modal.annexaFormly.options = {};
                            modal.annexaFormly.options.formState = {readOnly: false};
                            if(modal.annexaFormly.fields && modal.annexaFormly.fields.length > 2 && modal.annexaFormly.fields[2].fieldGroup && modal.annexaFormly.fields[2].fieldGroup.length > 0 && modal.annexaFormly.fields[2].fieldGroup[0].templateOptions){
                                modal.annexaFormly.fields[2].fieldGroup[0].templateOptions.query = query;
                            }
                            var parameters = $linq(query.parameters).orderBy("x => x.viewOrder").toArray();
                            _.forEach(parameters, function (field, index) {
                                var fieldClass = 'col-sm-4';
                                if (index % 3 === 0) {
                                    fieldClass += ' col-0';
                                }
                                var newField = QueryFactory.createQueryParameterField(field, false, false, fieldClass);
                                if(newField){
                                    modal.annexaFormly.fields[1].fieldGroup.push(newField);
                                    if(field.queryParameter.queryParameterType == 'DATE' && field.value) {
                                        var dateParts = field.value.split("/");
                                        var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
                                        modal.annexaFormly.model.additionalFilter[newField.key] = dateObject;
                                    }else if(field.queryParameter.queryParameterType == 'INTEGER'&& field.value){
                                        modal.annexaFormly.model.additionalFilter[newField.key] =  parseInt(field.value);
                                    }else if(field.queryParameter.queryParameterType == 'DOUBLE'&& field.value){
                                        var valueLiteral_parts = (field.value).split('.');
                                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                                            if(valueLiteral_parts[1]){
                                                modal.annexaFormly.model.additionalFilter[newField.key] =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                            }else{
                                                modal.annexaFormly.model.additionalFilter[newField.key] =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                            }
                                        }
                                    }else if(field.queryParameter.queryParameterType == 'BOOLEAN'){
                                        if(field.value && field.value != '-1'){
                                            modal.annexaFormly.model.additionalFilter[newField.key] = field.value;
                                        }
                                    }else if(field.queryParameter.queryParameterType == 'BOOLEAN_NULL'){
                                        if(field.value && field.value != '-1'){
                                            modal.annexaFormly.model.additionalFilter[newField.key] = field.value;
                                        }else{
                                            modal.annexaFormly.model.additionalFilter[newField.key] = undefined;
                                        }
                                    }else if(field.value){
                                        modal.annexaFormly.model.additionalFilter[newField.key] = field.value;
                                    }
                                }
                            });
                            modal.extra = ((query && query.id)?query.id:undefined);
                            $scope.$on('executeQueryResultError',function(event,args){
                                if(args && args.queryId && args.queryId == query.id) {
                                	if (args.error && args.error.status == 500) {
                                		modal.alerts.push({msg: $filter('translate')('global.querys.execute.queryError'), msgAux:((args.error.message)?args.error.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
                                	} else {
                                		modal.alerts.push({msg: $filter('translate')('global.querys.execute.error')});
                                	}
                                }
                            });
                            AnnexaFormlyFactory.showModal('modalQuery', modal, $scope.executeQuery, false, undefined, $filter('translate')('global.querys.execute.execute'));
                        }
                    });
            }
        };

        $scope.executeQuery = function(){
            if (this.annexaFormly.form.$valid) {
                var datepickerFields = $linq(this.annexaFormly.fields[1].fieldGroup).where("x => x.type == 'annexaDatepickerRow'").toArray();
                for (var i = 0; i < datepickerFields.length; i++) {
                    var modelDatepickerField = this.annexaFormly.model.additionalFilter[datepickerFields[i].key];
                    if (modelDatepickerField) {
                        var dateUTC = new Date(Date.UTC(modelDatepickerField.getFullYear(),modelDatepickerField.getMonth(),modelDatepickerField.getDate(),00,00,00));
                        this.annexaFormly.model.additionalFilter[datepickerFields[i].key] = dateUTC;
                    }
                }
                var modelAdditionalFilter = this.annexaFormly.model.additionalFilter;
                modelAdditionalFilter.langColumn = $scope.languageColumn;
                modelAdditionalFilter.loggedUser = $rootScope.LoggedUser.id;
                $rootScope.$broadcast('executeQueryResult', {queryId:this.extra, additionalFilter:modelAdditionalFilter, modal:this});
            }
        };

        $scope.columnsAux = [
            {id: $scope.languageColumn, column: new StrippedColumn($filter, 'global.literals.name', 100)}
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        $scope.columnsListOfQuery = angular.copy($scope.columnsAux);
        $scope.columnsListOfQuery.push({
            id: 'actions',
            columnName: 'id',
            width: '170px',
            className: 'text-center',
            title: userActionsColumn.getTitle(),
            render: userActionsColumn.getRender,
            sortable: false
        });
        $scope.columnsListOfQuery = getDatatableColumnsSettings(datatableSettings, 'datatable_execute_query', $scope.columnsListOfQuery);

        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_execute_query', $scope.columnsListOfQuery);
        $scope.tableOrderProperties = {sortName: 'id', sort: [[0, 'desc']]};
        if (orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0) {
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index, orderProperties.direction]]
            };
        }

        $scope.tableDefinition = {
            id: 'tableListOfQuery',
            origin: 'queries',
            objectType: 'Query',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: $scope.getFilterCall(),
            filterCallAux: $scope.getFilterCallAux(),
            filterCallFunc: $scope.getFilterCall,
            filterCallAuxFunc: $scope.getFilterCallAux,
            columns: angular.copy($scope.columnsListOfQuery),
            containerScope: $scope
        }


        function getFilterCall() {
            var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
            filterCall.valid = true;
            return filterCall;
        };


        function getFilterCallAux() {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = $scope.languageColumn;
            filterCallAux.module = "querys";
            return filterCallAux;
        };

        function onChangeState(message) {
            $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];
            if(AnnexaPermissionsFactory.havePermission(['si_query_execute']) && AnnexaPermissionsFactory.havePermission(['si_report_execute'])) {
                $rootScope.subHeadTabs = [
                    {
                        state: 'annexa.querys.executeQuery',
                        type: 'breadDivButton active',
                        name: 'global.querys.execute.toptitle',
                        permissions: ['si_query_execute']
                    },
                    {
                        state: 'annexa.querys.executeReport',
                        type: 'breadDivButton',
                        name: 'global.querys.report.toptitle',
                        permissions: ['si_report_execute']
                    },
                ];
            }
        };
    }])
    .controller('ExecuteReportController',['$rootScope', '$scope', '$state', '$filter', '$stateParams', 'HeaderService', '$timeout', 'RouteStateFactory', 'TableFilterFactory', 'RestService', 'QuerysModals', 'AnnexaFormlyFactory','QueryFactory','AnnexaPermissionsFactory','HelperService',
        function ($rootScope, $scope, $state, $filter, $stateParams, HeaderService, $timeout, RouteStateFactory, TableFilterFactory, RestService, QuerysModals, AnnexaFormlyFactory,QueryFactory,AnnexaPermissionsFactory,HelperService) {
            if (!$scope.tfilter){
                var i = 0;
                $scope.tfilter = [
                    {id: $scope.languageColumn, type: 'text', order: i++, label: 'global.literals.name', preFilter:HelperService.getPrefilter('querys','tableListOfReports','language'), negated:HelperService.getPrefilterNegated('querys','tableListOfReports','language')}
                ];
            }
            var routeState = RouteStateFactory.getRouteState($state.current);
            if(routeState) {
                $scope.tfilter = routeState.state;
            }
            $scope.getFilterCall = getFilterCall;
            $scope.getFilterCallAux = getFilterCallAux;
            $scope.submit = submit;

            function submit(val) {
                $scope.$broadcast('filterData', {});
            };

            HeaderService.onChangeState($scope, onChangeState);
            HeaderService.changeState($state.current);
            var userActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.querys.report.executeReport','openExecuteReport([data])','fa-cog', false),[]);


            $scope.openExecuteReport = function (id) {
                if(id) {
                    RestService.findOne('Report', id, 'reportsModule')
                        .then(function(data) {
                            if(data && data.data) {
                                var report = JSOG.decode(data.data);
                                var modal = angular.copy(QuerysModals.reportExecute);
                                modal.annexaFormly.model = {};
                                modal.annexaFormly.model.row1 =  {name: report[$scope.languageColumn], description:report.description};
                                modal.annexaFormly.model.additionalFilter =  {description:report.description};
                                modal.annexaFormly.options = {};
                                modal.annexaFormly.options.formState = {readOnly: false};
                                if(report.queries && report.queries.length > 0){
                                    if(modal.annexaFormly.fields && modal.annexaFormly.fields.length > 1 && modal.annexaFormly.fields[1].fieldGroup) {
                                        var newField = {
                                            key: 'description',
                                            type: 'annexaLabel',
                                            className: 'col-sm-12',
                                            templateOptions: {
                                                label: '',
                                                value: ''
                                            },
                                            data: {
                                                informed: true,
                                                row: true,
                                                colClass: ' col-sm-12',
                                                labelClass: 'label-strong'
                                            },
                                            controller: function ($scope, $filter) {
                                                $scope.to.value = $filter("translate")("global.querys.report.querys") + ":";
                                            }
                                        };
                                        modal.annexaFormly.fields[1].fieldGroup.push(newField);
                                        var queryFields = [];
                                        _.forEach(report.queries, function (query) {
                                            var newQueryField = {
                                                key: query.alias,
                                                className: '',
                                                templateOptions: {},
                                                fieldGroup: [{
                                                    key: 'description',
                                                    type: 'annexaLabel',
                                                    className: 'col-sm-12',
                                                    templateOptions: {
                                                        label: '',
                                                        value: ''
                                                    },
                                                    data: {
                                                        informed: true,
                                                        row: true,
                                                        colClass: ' col-sm-12',
                                                        labelClass: 'label-strong'
                                                    },
                                                    controller: function ($scope, $filter, Language) {
                                                        $scope.to.value = query.query[Language.getActiveColumn()];
                                                    }
                                                }]
                                            };
                                            modal.annexaFormly.model.additionalFilter[query.alias] = {};
                                            var parameters = ((query && query.query && query.query.parameters)?$linq(query.query.parameters).orderBy("x => x.viewOrder").toArray():[]);
                                            _.forEach(parameters, function (field, index) {
                                                var fieldClass = 'col-sm-4';
                                                if (!report.joinCommonParams) {
                                                    if (index % 3 === 0) {
                                                        fieldClass += ' col-0';
                                                    }
                                                }
                                                var newParameterField = QueryFactory.createQueryParameterField(field, false, false, fieldClass);
                                                if(newParameterField){
                                                    newQueryField.fieldGroup.push(newParameterField);
                                                    queryFields.push(newParameterField);
                                                    if(field.queryParameter.queryParameterType == 'DATE' && field.value) {
                                                        var dateParts = field.value.split("/");
                                                        var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
                                                        modal.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = dateObject;
                                                    }else if(field.queryParameter.queryParameterType == 'INTEGER'&& field.value){
                                                        modal.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] =  parseInt(field.value);
                                                    }else if(field.queryParameter.queryParameterType == 'DOUBLE'&& field.value){
                                                        var valueLiteral_parts = (field.value).split('.');
                                                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                                                            if(valueLiteral_parts[1]){
                                                                modal.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                                            }else{
                                                                modal.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                                            }
                                                        }
                                                    }else if(field.queryParameter.queryParameterType == 'BOOLEAN'){
                                                        if(field.value && field.value != '-1'){
                                                            modal.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = field.value;
                                                        }
                                                    }else if(field.queryParameter.queryParameterType == 'BOOLEAN_NULL'){
                                                        if(field.value && field.value != '-1'){
                                                            modal.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = field.value;
                                                        }else{
                                                            modal.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = undefined;
                                                        }
                                                    }else if(field.value){
                                                        modal.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = field.value;
                                                    }
                                                }
                                            });
                                            modal.annexaFormly.fields[1].fieldGroup.push(newQueryField);
                                        });

                                        if (report.joinCommonParams) {
                                            var newCommonField = {
                                                key: 'commonParams',
                                                className: '',
                                                templateOptions: {},
                                                fieldGroup: [{
                                                    key: 'description',
                                                    type: 'annexaLabel',
                                                    className: 'col-sm-12',
                                                    templateOptions: {
                                                        label: '',
                                                        value: ''
                                                    },
                                                    data: {
                                                        informed: true,
                                                        row: true,
                                                        colClass: ' col-sm-12',
                                                        labelClass: 'label-strong'
                                                    },
                                                    controller: function ($scope, $filter, Language) {
                                                        $scope.to.value = $filter("translate")("global.querys.report.commonParams") + ":";
                                                    }
                                                }]
                                            };
                                            modal.annexaFormly.fields[1].fieldGroup.splice(1,0,newCommonField);

                                            var search = queryFields.reduce(function(acc, field) {
                                                var clave = JSON.stringify(field);
                                                acc[clave] = ++acc[clave] || 0;
                                                return acc;
                                            }, {});
                                            var duplicates = angular.copy(queryFields).filter(function(field) {
                                                return search[JSON.stringify(field)];
                                            });

                                            modal.annexaFormly.model.additionalFilter.commonParams = {};
                                            for (var i = 0; i < duplicates.length; i++) {
                                                var indexCommon = $linq(newCommonField.fieldGroup).indexOf("x => x.key == '" + duplicates[i].key + "'");
                                                if (indexCommon == -1) {
                                                    _.forEach(modal.annexaFormly.fields[1].fieldGroup, function (fieldGroup) {
                                                        var index = $linq(fieldGroup.fieldGroup).indexOf("x => x.key == '" + duplicates[i].key + "'");
                                                        if (index != -1) {
                                                            fieldGroup.fieldGroup[index].hideExpression = function () {return true;};
                                                            modal.annexaFormly.model.additionalFilter.commonParams[duplicates[i].key] = modal.annexaFormly.model.additionalFilter[fieldGroup.key][duplicates[i].key];
                                                        }
                                                    });
                                                    newCommonField.fieldGroup.push(duplicates[i]);
                                                }
                                            }
                                        }
                                    }
                                }
                                modal.extra = ((report && report.id)?report.id:undefined);
                                $scope.$on('executeReportResultError',function(event,args){
                                    if(args && args.reportId && args.reportId == report.id) {
                                    	if (args.error && args.error.status == 500) {
                                    		modal.alerts.push({msg: $filter('translate')('global.querys.report.reportError'), msgAux:((args.error.message)?args.error.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
                                    	} else {
                                    		modal.alerts.push({msg: $filter('translate')('global.querys.report.error')});
                                    	}
                                    }
                                });
                                AnnexaFormlyFactory.showModal('modalQuery', modal, $scope.executeReport, false, undefined, $filter('translate')('global.querys.report.generate'));
                            }
                        });
                }
            };

            $scope.executeReport = function(){
                if (this.annexaFormly.form.$valid) {
                    var self = this;
                    for (var i = 1; i < this.annexaFormly.fields[1].fieldGroup.length; i++) {
                        var datepickerFields = $linq(this.annexaFormly.fields[1].fieldGroup[i].fieldGroup).where("x => x.type == 'annexaDatepickerRow'").toArray();
                        for (var j = 0; j < datepickerFields.length; j++) {
                            var modelDatepickerField = this.annexaFormly.model.additionalFilter[this.annexaFormly.fields[1].fieldGroup[i].key][datepickerFields[j].key];
                            if (modelDatepickerField) {
                                var dateUTC = new Date(Date.UTC(modelDatepickerField.getFullYear(),modelDatepickerField.getMonth(),modelDatepickerField.getDate(),00,00,00));
                                this.annexaFormly.model.additionalFilter[this.annexaFormly.fields[1].fieldGroup[i].key][datepickerFields[j].key] = dateUTC;
                            }
                        }
                    }
                    var modelAdditionalFilter = this.annexaFormly.model.additionalFilter;
                    if (modelAdditionalFilter.commonParams) {
                        var indexCommonParams = $linq(this.annexaFormly.fields[1].fieldGroup).indexOf("x => x.key == 'commonParams'");
                        for (var i = indexCommonParams; i < this.annexaFormly.fields[1].fieldGroup.length; i++) { //recorremos las consultas
                            for (var j = 1; j < this.annexaFormly.fields[1].fieldGroup[indexCommonParams].fieldGroup.length; j++) { //recorremos los campos comunes
                                if (modelAdditionalFilter.commonParams[this.annexaFormly.fields[1].fieldGroup[indexCommonParams].fieldGroup[j].key]) {
                                    //si se ha informado el campo común entonces se informará su valor en cada uno de los campos de las consultas
                                    modelAdditionalFilter[this.annexaFormly.fields[1].fieldGroup[i].key][this.annexaFormly.fields[1].fieldGroup[indexCommonParams].fieldGroup[j].key] =
                                        modelAdditionalFilter.commonParams[this.annexaFormly.fields[1].fieldGroup[indexCommonParams].fieldGroup[j].key];
                                }
                            }
                        }
                    }
                    modelAdditionalFilter.langColumn = $scope.languageColumn;
                    modelAdditionalFilter.loggedUser = $rootScope.LoggedUser.id;
                    QueryFactory.generateReportQuery(self.extra, modelAdditionalFilter, self);
                }
            };

            $scope.columnsAux = [
                {id: $scope.languageColumn, column: new StrippedColumn($filter, 'global.literals.name', 100)}
            ];

            var datatableSettings = $rootScope.app.configuration.datatables_settings;
            $scope.columnsListOfReport= angular.copy($scope.columnsAux);
            $scope.columnsListOfReport.push({
                id: 'actions',
                columnName: 'id',
                width: '170px',
                className: 'text-center',
                title: userActionsColumn.getTitle(),
                render: userActionsColumn.getRender,
                sortable: false
            });
            $scope.columnsListOfReport = getDatatableColumnsSettings(datatableSettings, 'datatable_execute_report', $scope.columnsListOfReport);

            var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_execute_report', $scope.columnsListOfReport);
            $scope.tableOrderProperties = {sortName: 'id', sort: [[0, 'desc']]};
            if (orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0) {
                $scope.tableOrderProperties = {
                    sortName: orderProperties.name,
                    sort: [[orderProperties.index, orderProperties.direction]]
                };
            }

            $scope.tableDefinition = {
                id: 'tableListOfReports',
                origin: 'reports',
                objectType: 'Report',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: $scope.getFilterCall(),
                filterCallAux: $scope.getFilterCallAux(),
                filterCallFunc: $scope.getFilterCall,
                filterCallAuxFunc: $scope.getFilterCallAux,
                columns: angular.copy($scope.columnsListOfReport),
                containerScope: $scope
            }


            function getFilterCall() {
                var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
                return filterCall;
            };


            function getFilterCallAux() {
                var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
                filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                filterCallAux.langColumn = $scope.languageColumn;
                filterCallAux.module = "reports";
                return filterCallAux;
            };

            function onChangeState(message) {
                $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];
                if(AnnexaPermissionsFactory.havePermission(['si_query_execute']) && AnnexaPermissionsFactory.havePermission(['si_report_execute'])) {
                    $rootScope.subHeadTabs = [
                        {
                            state: 'annexa.querys.executeQuery',
                            type: 'breadDivButton',
                            name: 'global.querys.execute.toptitle',
                            permissions: ['si_query_execute']
                        },
                        {
                            state: 'annexa.querys.executeReport',
                            type: 'breadDivButton active',
                            name: 'global.querys.report.toptitle',
                            permissions: ['si_report_execute']
                        },
                    ];
                }else{
                    $rootScope.subHeadTabs = [];
                }
            };
    }]);